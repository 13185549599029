import * as React from "react";
import "normalize.css";
import "../style/Fonts.css";
import "../style/layouts.css";
import Layout from "../components/layout";
import UEp from "../components/paragragh";
import TitleCard from "../components/title";
import PictureCard from "../components/ProcessPcard";
import ProcessBG from "../components/ProcessBG";
import Footer from "../components/footer"
import process1 from "../images/mobile/Step1.png";
import SubTitle from "../components/subtitle";
import process2 from "../images/mobile/Step2.png";
import process3 from "../images/mobile/Step3.png";
import connector from "../images/mobile/connector.png";
import styled from 'styled-components'
import * as constants from '../style/constants'

const StyledMain = styled.main`
padding-top:70px;
background-color:${constants.white};
font-family: ${constants.Dfont},${constants.Cfont};
`
const ProcessPage= () => {
  return (
    <Layout responsive={'phone'}>
      <StyledMain>
        <title>Our Process</title>
        <ProcessBG>
          <SubTitle>
            我们的<span style={{ color: `${constants.brightred}` }}>流程</span>
            <br/>简要精准: 聚焦玩家
          </SubTitle>
        </ProcessBG>
        <div style={{ marginTop: "340px" }}></div>
        <PictureCard
          src={process1}
          title={
            <span>
              <span style={{ color: `${constants.purple}` }}>深入</span>试玩
            </span>
          }
          width={"90%"}
          height={"auto"}
          color={constants.purple}
        >
          为了保持您游戏的独特性，我们工作的第一步是深入试玩。由此，我们会定制该游戏的本土化方案及行动计划
        </PictureCard>
        <div
          style={{
            marginTop: "2vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={connector} style={{ width: "auto", height: "300px" }} />
        </div>
        <PictureCard
          src={process2}
          title={
            <span style={{ color: `${constants.brightred}` }}>
              <span style={{ color: `${constants.black}` }}>创作</span>
              全球化
            </span>
          }
          width={"90%"}
          height={"auto"}
          color={constants.black}
        >
          我们对游戏行业的深入了解让我们可以从客户创作游戏开始就参与项目，让整个游戏流程与本地文化及玩家习惯更为融合，确保我们的客户从游戏起步即踏上成功的第一步
        </PictureCard>
        <div
          style={{
            marginTop: "2vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={connector} style={{ width: "auto", height: "300px" }} />
        </div>
        <PictureCard
          src={process3}
          nopush={"true"}
          title={
            <span>
              <span style={{ color: `${constants.brightred}` }}>代入式</span>翻译
            </span>
          }
          width={"90%"}
          height={"auto"}
          color={constants.brightred}
        >
          我们会转换角度，从玩家的体验出发，与您的团队一起探索游戏过程重的问题及潜在漏洞，使玩家能沉浸于游戏当中
        </PictureCard>
        <div
          style={{ marginTop: "5rem", marginLeft: "10px", marginRight: "10px", paddingBottom:'30px' }}
        >
          <UEp style={{fontSize:'18px', lineHeight:'25px'}}>
            还不确定我们是否可以处理您的项目吗？联系我们，我们很乐意提供历史合作案例供您参考。
          </UEp>
        </div>
      </StyledMain>
      <Footer />
    </Layout>
  );
};

export default ProcessPage;
