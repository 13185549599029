import React from "react";
import styled from "styled-components";
import ProcessBG from "../images/mobile/ProcessBG.svg";

const StyledImg = styled((props) => <img {...props} />)`
  position: absolute;
  width: 100%;
  height: 264px;
  object-fit: cover;
  @media screen and (min-width: 414px) and (max-width: 767px) {
    height: 340px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 500px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 100%;
    height: 500px;
  }
  @media screen and (min-width: 1440px) {
    width: 100%;
    height: 100vh;
  }
`;
const StyledGradient = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 264px;
  background: linear-gradient(
    71.48deg,
    #283747 -4.09%,
    rgba(196, 196, 196, 0.56) 122.27%
  );
  @media screen and (min-width: 414px) and (max-width: 767px) {
    height: 340px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 500px;
    align-items: start;
    justify-content: center;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 500px;
    justify-content: center;
    align-items: start;
  }
  @media screen and (min-width: 1440px) {
    height: 100vh;
    justify-content: center;
    align-items: start;
  }
`;
const Background = ({ children, props }) => {
  return (
    <div>
      <StyledImg src={ProcessBG} />
      <StyledGradient>{children}</StyledGradient>
    </div>
  );
};
export default Background;
