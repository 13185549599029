import React from "react";
import styled from "styled-components";
import UEp from "./smalpp";
import * as constants from "../style/constants";
import { constant } from "lodash";

const StyledPcard = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-top:30px;
    ${constants.Laptop}{
        max-width:600px;
        padding-top:0px;
    }
    ${constants.Desktop}{
        max-width:700px;
    }
`
const StyledIMG = styled.img`
    ${constants.SmallPhone}{
    margin-top:${props => props.nopush? "10px" : "-40px"};
    margin-bottom:${props => props.nopush? "20px" : "0px"};
    }
    ${constants.Phone}{
    }
    ${constants.Tablet}{
    margin-top:${props => props.nopush? "30px" : "-80px"};
    margin-bottom:${props => props.nopush? "50px" : "0px"};
    }
    ${constants.Laptop}{
    margin-top:${props => props.nopush? "20px" : "-60px"};
    margin-bottom:${props => props.nopush? "50px" : "0px"};
    }
    ${constants.Desktop}{
    margin-top:${props => props.nopush? "20px" : "-60px"};
    margin-bottom:${props => props.nopush? "50px" : "0px"};
    }
`
const StyledTitle = styled.div`
    ${constants.SmallPhone}{
    font-size:${constants.P2fontsize};
    font-color:${constants.black};
    z-index:1;
    }
    ${constants.Phone}{
    font-size:${constants.P2fontsizeP};
    font-color:${constants.black};
    z-index:1;
    }
    ${constants.Tablet}{
    font-size:${constants.P2fontsizeT};
    font-color:${constants.black};
    z-index:1;
    }
    ${constants.Laptop}{
    font-size:${constants.P2fontsizeT};
    line-height:40px;
    font-color:${constants.black};
    z-index:1;
    }
    ${constants.Desktop}{
    font-size:${constants.P2fontsizeT};
    line-height:40px;
    font-color:${constants.black};
    z-index:1;
    }
`
const StyledP = styled(UEp)
`
    ${constants.SmallPhone}{
    font-size:${constants.P2fontsize};
    color:${props=> props.color};
    width:90%;
    }
    ${constants.Phone}{
    font-size:${constants.P2fontsizeP};
    color:${props=> props.color};
    width:90%;
    }
    ${constants.Tablet}{
    font-size:${constants.P2fontsizeT};
    color:${props=> props.color};
    width:90%;
    }
    ${constants.Laptop}{
    font-size:25px;
    color:${props=> props.color};
    line-height:${constants.Content2HeightT};
    width:80%;
    height:50%;
    }
    ${constants.Desktop}{
    font-size:25px;
    color:${props=> props.color};
    line-height:${constants.Content2HeightT};
    width:80%;
    height:50%;
    }
`
export default function ProcessPcard({ children, src, title, width, height, className, nopush, color}) {
    return (
        <StyledPcard className={className}>
            <StyledTitle>
            {title}
</StyledTitle>
        <StyledIMG src={src} style={{width:width, height: height}} nopush={nopush} />
         <StyledP color={color}>{children}</StyledP>
        </StyledPcard>
    )
}
