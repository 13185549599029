import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";

const StyledP = styled((props) => <span {...props} />)`
  color: ${constants.black};
  line-height: ${constants.ContentHeight};
  font-family: ${constants.Cfont},${constants.Dfont};
  font-size: ${constants.Pfontsize};
  font-weight: ${props => props.bold? constants.PBoldWeight : constants.PRegWeight};
  display:inline;

  @media screen and (max-width: 375px) and (max-width 1023px) {
    font-size: ${constants.PfontsizeSP};
    font-weight:${props => props.bold? constants.PBoldWeightSP : constants.PRegWeightSP};
    line-height: ${constants.ContentHeightSP};
    margin-top:0;
  }
`;
export default function Paragaph({ children, bold, className, style}) {
  return <StyledP bold={bold} className={className} style={style}>{children}</StyledP>;
}