import * as React from "react";
import Navbar from "../components/Navbar";
import UEp from "../components/paragragh";
import "normalize.css";
import "../style/Fonts.css";
import "../style/layouts.css";
import Layout from "../components/layout";
import PictureCard from "../components/ProcessPcard";
import ProcessBG from "../components/ProcessBG";
import process1 from "../images/mobile/Step1.png";
import SubTitle from "../components/subtitle";
import Footer from "../components/footer";
import process2 from "../images/mobile/Step2.png";
import process3 from "../images/mobile/Step3.png";
import connector1 from "../images/desktop/Processline1.png";
import connector2 from "../images/desktop/Processline2.png";
import styled from "styled-components";
import * as constants from "../style/constants";

const StyledMain = styled.main`
  background-color: ${constants.white};
  font-family: ${constants.Dfont}, ${constants.Cfont};
`;
const StyledContainer = styled.div`
  display: flex;
  padding-left: 50px;
  flex-direction: row;
  width: 70%;
`;
const ProcessPage = () => {
  return (
    <Layout responsive={"laptop"}>
      <StyledMain>
        <Navbar />
        <title>Our Process</title>
        <ProcessBG>
          <SubTitle>
            我们的<span style={{ color: `${constants.brightred}` }}>流程</span>
            <br/>简要精准: 聚焦玩家
          </SubTitle>
        </ProcessBG>
        <div
          style={{
            paddingTop: "600px",
            display: "flex",
            paddingLeft: "50px",
            flexDirection: "row",
            width: "900px",
          }}
        >
          <PictureCard
            src={process1}
            title={
              <span>
              <span style={{ color: `${constants.purple}` }}>深入</span>试玩
              </span>
            }
            width={"400px"}
            height={"auto"}
            color={constants.purple}
          >
          为了保持您游戏的独特性，我们工作的第一步是深入试玩。由此，我们会定制该游戏的本土化方案及行动计划
          </PictureCard>
          <div
            style={{
              marginTop: "2vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={connector1}
              style={{ width: "auto", height: "250px", paddingTop: "140px", marginLeft:'-80px' }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            paddingLeft: "100px",
            paddingTop:'50px',
            justifyContent:'space-between',
            flexDirection: "row",
            width: "900px",
          }}
        >
          <div
            style={{
              marginTop: "20vh",
              display: "flex",
              justifyContent: "center",
              paddingLeft:"20vh"
            }}
          >
            <img src={connector2} style={{ width: "auto", height: "250px", paddingTop: '60px'}} />
          </div>
          <PictureCard
            src={process2}
            title={
              <span style={{ color: `${constants.brightred}` }}>
              <span style={{ color: `${constants.black}` }}>创作</span>
              全球化
              </span>
            }
            width={"400px"}
            height={"auto"}
            color={constants.black}
          >
          我们对游戏行业的深入了解让我们可以从客户创作游戏开始就参与项目，让整个游戏流程与本地文化及玩家习惯更为融合，确保我们的客户从游戏起步即踏上成功的第一步
          </PictureCard>
        </div>
        <div style={{marginLeft:'30px'}}>
        <PictureCard
          src={process3}
          nopush={"true"}
          title={
            <span>
              <span style={{ color: `${constants.brightred}` }}>代入式</span>翻译
            </span>
          }
          width={"400px"}
          height={"auto"}
          color={constants.brightred}
        >
          我们会转换角度，从玩家的体验出发，与您的团队一起探索游戏过程重的问题及潜在漏洞，使玩家能沉浸于游戏当中
        </PictureCard>
</div>
        <div
          style={{ marginTop:"3rem", display:'flex', justifyContent:'center'}}
        >
          <UEp style={{ fontSize: "25px", lineHeight: "35px", textAlign:'center', marginBottom:'40px'}}>
            还不确定我们是否可以处理您的项目吗？联系我们，我们很乐意提供历史合作案例供您参考。
          </UEp>
        </div>
      </StyledMain>
<Footer />
    </Layout>
  );
};

export default ProcessPage;
