import React from 'react'
import styled from 'styled-components'
import UEp from "./paragragh";
import * as constants from "../style/constants";
const SubTitle = styled(UEp)`
  @media screen and (max-width: 375px) {
    font-family: ${constants.Cfont};
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: ${constants.white};
    margin-bottom:20px;
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
    font-family: ${constants.Cfont};
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: ${constants.white};
    margin-bottom:20px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-family: ${constants.Cfont};
    font-size: 35px;
    line-height: 60px;
    text-align: left;
    color: ${constants.white};
    padding-top: 30px;
    padding-left:50px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    font-family: ${constants.Cfont};
    font-size: 35px;
    line-height: 60px;
    text-align: left;
    color: ${constants.white};
    padding-left:50px;
  }
  @media screen and (min-width: 1440px) {
    font-family: ${constants.Cfont};
    font-size: 55px;
    line-height: 110px;
    text-align: left;
    color: ${constants.white};
    padding-left:80px;
  }
`;
export default function subtitle({children, className}) {
    return (
        <SubTitle className={className}>{children}</SubTitle>
    )
}
