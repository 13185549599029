import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Desktop from "../Responsive/processDesktop";
import SmallPhone from "../Responsive/processSmallphone"
import Phone from "../Responsive/processPhone"
import Tablet from "../Responsive/processTablet"
import Laptop from "../Responsive/processLaptop"
import "normalize.css";
const IndexPage = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1440px)" });
  const isSmallPhone= useMediaQuery({ query: "(max-width: 413px)" });
  const isPhone= useMediaQuery({ query: "(min-width: 414px) and (max-width: 767px)" });
  const isLaptop= useMediaQuery({ query: "(min-width: 1024px) and (max-width: 1439px)" });
  const isTablet= useMediaQuery({ query: "(min-width: 768px) and (max-width: 1023px)" });
  return <>
  {isBigScreen && <Desktop />}
  {isSmallPhone&& <SmallPhone/>}
  {isPhone&& <Phone/>}
  {isTablet&& <Tablet/>}
  {isLaptop && <Laptop/>}
  </>;
};

export default IndexPage;
